<template>
  <div>
    <con-tab @TwoClick="TwoClick"></con-tab>
    <!-- 专利发布 -->
    <XQ></XQ>
  </div>
</template>

<script>
import ConTab from "../../components/common/ConTab";
import XQ from "./base/XQ";
// import Qs from 'qs'
export default {
  name: "release",
  data() {
    return {};
  },
  created() {},
  methods: {
    TwoClick(item) {
      //console.log(item);
      this.ConList = item;
    },
  },
  components: {
    ConTab,
    XQ,
  },
};
</script>

<style scoped>
</style>
