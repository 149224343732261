 <template>
  <div class="details-one">
    <div class="w">
      <div style="height: 20px"></div>

      <div class="head_detail">
        <div class="clearfix">
          <div class="head_detail_img left">
            <div class="head_detail_img_show">
              <div v-if="XQ.ptype">
                <img
                  v-if="XQ.ptype.id === 139"
                  src="../../../assets/img/zhichanyun/sb.jpg"
                  alt="证书"
                />
                <img
                  v-else-if="XQ.ptype.id === 138"
                  src="../../../assets/img/zhichanyun/bq.jpg"
                  alt="证书"
                />
                 <img
                  v-else-if="XQ.ptype.id === 140"
                  src="../../../assets/img/zhichanyun/qt.jpg"
                  alt="证书"
                />
                <span v-else>
                  <img
                    v-if="XQ.ptypes.name === '发明专利'"
                    src="../../../assets/img/zhichanyun/fm.jpg"
                    alt="证书"
                  />
                  <img
                    v-else-if="XQ.ptypes.name === '实用新型'"
                    src="../../../assets/img/zhichanyun/sy.jpg"
                    alt="证书"
                  />
                  <img
                    v-else
                    src="../../../assets/img/zhichanyun/wg.jpg"
                    alt="证书"
                  />
                </span>
              </div>
              <!-- <div v-else>
                <img
                  src="../../../assets/img/zhichanyun/sy.jpg"
                  alt="证书"
                />
              </div> -->
              <img
                class="hot_icon"
                src="../../../assets/img/zhichanyun/i16.png"
                alt=""
              />
              <div class="ptypes">
                {{ XQ.ptypes ? XQ.ptypes.name : "实用新型" }}
              </div>
            </div>
          </div>
          <div class="head_detail_show left">
            <div>
              <p class="head_detail_show_title" :title="XQ.name">
                {{ XQ.name }}
              </p>
            </div>
            <p class="head_detail_show_expiry">
              知识产权号：<input
                readonly="readonly"
                id="copycode1"
                type="text"
                :value="XQ.notary_number"
              />
            </p>
            <div class="head_detail_show_price">
              <div class="head_detail_show_price_row1">
                <span>出售价格：</span>

                <span class="price"> {{ XQ.sale_money }}元</span>
              </div>
               <div class="head_detail_show_price_row1" style="margin-top:25px">
                <span>联系电话：</span>

                <span > {{ XQ.phone }} </span>
              </div>
            </div>
            <div class="head_detail_show_type clearfix">
              <div class="head_detail_show_type_each left">
                <p class="head_detail_show_type_each_p1">
                  {{ XQ.ptypes ? XQ.ptypes.name : "实用新型" }}
                </p>
                <p class="head_detail_show_type_each_p2">知识产权类别</p>
              </div>
              <div class="head_detail_show_type_each left">
                <p class="head_detail_show_type_each_p1">
                  {{ XQ.ptype ? XQ.ptype.name : "" }}
                </p>
                <p class="head_detail_show_type_each_p2">产权类型</p>
              </div>
              <div class="head_detail_show_type_each left">
                <p class="head_detail_show_type_each_p1">
                  {{ XQ.apply_class == 1 ? "个人" : "企业" }}
                </p>
                <p class="head_detail_show_type_each_p2">发布性质</p>
              </div>
            </div>

            <!-- <div class="head_detail_show_btns clearfix">
            <a href="javascript:;" class="buy left immediate_buy" data-id="6Y2JED"
              >立即购买</a
            >
            <a
              class="consultation left"
              href="https://www.yuzhua.com/chat/yzzlzx.html"
              target="_blank"
              ><i></i>立即议价</a
            >
            <a class="cart left add_cart" href="javascript:;" data-id="6Y2JED"
              ><i></i>加入购物车</a
            >
          </div> -->
          </div>
        </div>
        <div class="head_detail_down">
          <div class="head_detail_down_l left">
            <span class="sign"><i></i>签署合同</span>
            <span class="assure"><i></i>安全交易</span>
            <span class="refund"><i></i>极速退款</span>
            <span class="below"><i></i>支持线下</span>
            <span class="confirm"><i></i>确认后放款</span>
          </div>
          <div class="head_detail_down_r right">百万资源供您选择</div>
        </div>
      </div>
      <div class="turning clearfix">
        <div class="turning_turn left">
          <!-- nav s -->
          <div class="turning_turn_wrap">
            <div
              class="turning_turn_nav clearfix"
              :style="styleObject"
              style="top: 0px; z-index: 9"
            >
              <span
                class="left"
                :class="idx == index ? 'on' : ''"
                v-for="(item, idx) in tab"
                :key="idx"
                data-target=".turn1"
                @click="goNavList('turn' + idx, idx)"
                >{{ item.name }}</span
              >
            </div>
          </div>
          <!-- nav e -->

          <!-- Trademark details s -->
          <div class="turning_turn_info" id="turn0" ref="turn0">
            <div class="commont">
              <i class="el-icon-menu"></i>
              <span>知识产权信息</span>
            </div>
            <div class="turning_turn_info_table clearfix">
              <div class="turning_turn_info_table_col1 table left">
                <div class="turning_turn_info_table_col_up">知识产权号</div>
                <div class="turning_turn_info_table_col1_down down">
                  <div class="turning_turn_info_table_col_down_in">
                    <p>{{ XQ.notary_number }}</p>
                  </div>
                </div>
              </div>
              <div class="turning_turn_info_table_col2 table left">
                <div class="turning_turn_info_table_col_up">知识产权名称</div>
                <div class="turning_turn_info_table_col2_down down">
                  <div class="turning_turn_info_table_col_down_in">
                    <p>{{ XQ.name }}</p>
                  </div>
                </div>
              </div>
              <div class="turning_turn_info_table_col3 table left">
                <div class="turning_turn_info_table_col_up">知识产权类别</div>
                <div class="turning_turn_info_table_col3_down down">
                  <div class="turning_turn_info_table_col_down_in">
                    <p>{{ XQ.ptypes ? XQ.ptypes.name : "实用新型" }}</p>
                  </div>
                </div>
              </div>
              <div class="turning_turn_info_table_col4 table left">
                <div class="turning_turn_info_table_col_up">产权类型</div>
                <div class="turning_turn_info_table_col4_down down">
                  <div class="turning_turn_info_table_col_down_in">
                    <p>{{ XQ.ptype ? XQ.ptype.name : "" }}</p>
                  </div>
                </div>
              </div>
              <div class="turning_turn_info_table_col5 table left">
                <div class="turning_turn_info_table_col_up">申请日期</div>
                <div class="turning_turn_info_table_col5_down down">
                  <div class="turning_turn_info_table_col_down_in">
                    <p>{{ XQ.registration_date | formatDate }}</p>
                  </div>
                </div>
              </div>
              <div class="turning_turn_info_table_col6 table left">
                <div class="turning_turn_info_table_col_up">有效期至</div>
                <div class="turning_turn_info_table_col6_down down">
                  <div class="turning_turn_info_table_col_down_in">
                    <p>{{ XQ.end_date | formatDate }}</p>
                  </div>
                </div>
              </div>
              <div class="turning_turn_info_table_col7 table left">
                <div class="turning_turn_info_table_col_up">发布性质</div>
                <div class="turning_turn_info_table_col7_down down">
                  <div class="turning_turn_info_table_col_down_in">
                    <p>{{ XQ.apply_class == 1 ? "个人" : "企业" }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Trademark details e -->

          <!-- portent detail s -->
          <div class="turning_turn_pdetail" id="turn1">
            <div class="commont">
              <i class="el-icon-menu"></i>
              <span>知识产权详情</span>
            </div>
            <div class="turning_turn_pdetail_show">
              <p  v-html="XQ.ptext"> </p>
            </div>
          </div>
          <!-- portent detail e -->

          <!-- transfer process s -->
          <div class="turning_turn_process" id="turn2">
            <div class="commont">
              <i class="el-icon-menu"></i>
              <span>购买流程</span>
            </div>
            <div class="turning_turn_process_chart clearfix">
              <div class="turning_turn_process_chart_in">
                <div class="turning_turn_process_chart_each left on">
                  <div class="turning_turn_process_chart_each_num">
                    <p>0<span>1</span></p>
                  </div>
                  <p class="turning_turn_process_chart_each_name">
                    挑选中意的板块
                  </p>
                  <p class="turning_turn_process_chart_each_intro">
                    客服确认选择知识产权
                  </p>
                  <i></i>
                </div>
                <div class="turning_turn_process_chart_each left first">
                  <div class="turning_turn_process_chart_each_num">
                    <p>0<span>2</span></p>
                  </div>
                  <p class="turning_turn_process_chart_each_name">
                    办理转让材料
                  </p>
                  <p class="turning_turn_process_chart_each_intro">
                    协助双方准备相应
                  </p>
                  <i></i>
                </div>
                <div class="turning_turn_process_chart_each left second">
                  <div class="turning_turn_process_chart_each_num">
                    <p>0<span>3</span></p>
                  </div>
                  <p class="turning_turn_process_chart_each_name">签订协议</p>
                  <p class="turning_turn_process_chart_each_intro">
                    协助卖家签订协议
                  </p>
                  <i></i>
                </div>
                <div class="turning_turn_process_chart_each left third">
                  <div class="turning_turn_process_chart_each_num">
                    <p>0<span>4</span></p>
                  </div>
                  <p class="turning_turn_process_chart_each_name">
                    办理备受手续
                  </p>
                  <p class="turning_turn_process_chart_each_intro">
                    买卖双方达成一致
                  </p>
                  <i></i>
                </div>
                <div class="turning_turn_process_chart_each left fourth">
                  <div class="turning_turn_process_chart_each_num">
                    <p>0<span>5</span></p>
                  </div>
                  <p class="turning_turn_process_chart_each_name">交易完成</p>
                  <p class="turning_turn_process_chart_each_intro">
                    交易完成可投入使用
                  </p>
                  <i></i>
                </div>
              </div>
            </div>
          </div>
          <!-- transfer process e -->

          <!-- Transfer data s -->
          <div class="turning_turn_transfer" id="turn3">
            <div class="commont">
              <i class="el-icon-menu"></i>
              <span>过户资料</span>
            </div>
            <div class="turning_turn_transfer_table">
              <div class="turning_turn_transfer_table_title">
                买家双方需提供资料
              </div>
              <div class="turning_turn_transfer_table_apply">
                <div class="turning_turn_transfer_table_apply_row1 clearfix">
                  <div
                    class="turning_turn_transfer_table_apply_row1_col1"
                  ></div>
                  <div class="turning_turn_transfer_table_apply_row1_col2">
                    公司
                  </div>
                  <div class="turning_turn_transfer_table_apply_row1_col3">
                    个人
                  </div>
                </div>
                <div class="turning_turn_transfer_table_apply_row2 clearfix">
                  <div class="turning_turn_transfer_table_apply_row2_col1">
                    <div>买家</div>
                  </div>
                  <div class="turning_turn_transfer_table_apply_row2_col2">
                    <div>
                      <p>企业营业执照</p>
                      <p>企业组织机构代码证</p>
                    </div>
                  </div>
                  <div class="turning_turn_transfer_table_apply_row2_col3">
                    <div>
                      <p>身份证</p>
                    </div>
                  </div>
                </div>
                <div class="turning_turn_transfer_table_apply_row3 clearfix">
                  <div class="turning_turn_transfer_table_apply_row3_col1">
                    <div>卖家</div>
                  </div>
                  <div class="turning_turn_transfer_table_apply_row3_col2">
                    <div>
                      <p>企业营业执照</p>
                      <p>知识产权证书原件</p>
                    </div>
                  </div>
                  <div class="turning_turn_transfer_table_apply_row3_col3">
                    <div>
                      <p>身份证</p>
                      <p>知识产权证书原件</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="turning_turn_transfer_table_footer clearfix">
                <div class="turning_turn_transfer_table_footer_websit left">
                  <div class="turning_turn_transfer_table_footer_websit_t">
                    网站提供
                  </div>
                  <div class="turning_turn_transfer_table_footer_websit_con">
                    <div>
                      <p>知识产权代理委托书</p>
                      <p>知识产权权转让协议</p>
                      <p>办理文件副本请求书</p>
                      <p>发明人变更声明</p>
                    </div>
                  </div>
                </div>
                <div class="turning_turn_transfer_table_footer_get left">
                  <div class="turning_turn_transfer_table_footer_get_t">
                    过户后您将获得
                  </div>
                  <div class="turning_turn_transfer_table_footer_get_con">
                    <div>
                      <p>知识产权证书</p>
                      <p>手续合格通知书</p>
                      <p>知识产权登记簿副本</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Transfer data e -->

          <!-- safty guarantee s -->
          <div class="turning_turn_safty" id="turn4">
            <div class="commont">
              <i class="el-icon-menu"></i>
              <span
                >安全保障<i class="safty_in_t"
                  >知识产权交易，科汇全程监控，保护您的资金安全</i
                ></span
              >
            </div>
            <div class="turning_turn_safty_show clearfix">
              <div class="turning_turn_safty_show_each left">
                <div class="turning_turn_safty_show_each_in">
                  <img
                    src="https://style.yuzhua.com/public/mark/3.0/pdetail/images/safty_icon1.png"
                    alt=""
                  />
                  <p>签署交易合同</p>
                </div>
              </div>
              <div class="turning_turn_safty_show_each left">
                <div class="turning_turn_safty_show_each_in">
                  <img
                    src="https://style.yuzhua.com/public/mark/3.0/pdetail/images/safty_icon2.png"
                    alt=""
                  />
                  <p>三方安全交易</p>
                </div>
              </div>
              <div class="turning_turn_safty_show_each left">
                <div class="turning_turn_safty_show_each_in">
                  <img
                    src="https://style.yuzhua.com/public/mark/3.0/pdetail/images/safty_icon3.png"
                    alt=""
                  />
                  <p>提供资料证件</p>
                </div>
              </div>
              <div class="turning_turn_safty_show_each left">
                <div class="turning_turn_safty_show_each_in">
                  <img
                    src="https://style.yuzhua.com/public/mark/3.0/pdetail/images/safty_icon4.png"
                    alt=""
                  />
                  <p>极速退款</p>
                </div>
              </div>
              <div class="turning_turn_safty_show_each left">
                <div class="turning_turn_safty_show_each_in">
                  <img
                    src="https://style.yuzhua.com/public/mark/3.0/pdetail/images/safty_icon5.png"
                    alt=""
                  />
                  <p>支持线下</p>
                </div>
              </div>
              <div class="turning_turn_safty_show_each left">
                <div class="turning_turn_safty_show_each_in">
                  <img
                    src="https://style.yuzhua.com/public/mark/3.0/pdetail/images/safty_icon6.png"
                    alt=""
                  />
                  <p>确认后放款</p>
                </div>
              </div>
            </div>
          </div>
          <!-- safty guarantee e -->

          <!-- why choose kehuiyun s -->
          <div class="turning_turn_choose" id="turn5">
            <div class="commont">
              <i class="el-icon-menu"></i>
              <span>为什么选择科汇云</span
              ><i class="commont_info"
                >3000人专业团队，北上广深杭州、武汉、成都等十多个城市全布局</i
              >
            </div>
            <div class="turning_turn_choose_showyuzhua">
              <div class="turning_turn_choose_showyuzhua_up clearfix">
                <div class="smallimg left">
                  <div class="smallimg_up">
                    <img
                      src="../../../assets/img/zhichanyun/hj1.jpg"
                      alt="choose_img1"
                    />
                    <div class="smallimg_up_mask">
                      环境照-科汇云办公大厅
                    </div>
                  </div>
                  <div class="smallimg_down">
                    <img
                      src="../../../assets/img/zhichanyun/hj2.jpg"
                      alt="choose_img2"
                    />
                    <div class="smallimg_down_mask">环境照-科汇云接待室</div>
                  </div>
                </div>
                <div class="choose_lunbo left">
                  <el-carousel trigger="click" height="310px">
                    <el-carousel-item v-for="item in swiperList" :key="item.id">
                      <img :src="item.img" alt="choose_icon1" />
                      <div class="bigimg_mask">环境照-科汇云前台</div>
                    </el-carousel-item>
                  </el-carousel>

                  <!-- <div
                    class="tempWrap"
                    style="overleftow: hidden; position: relative; width: 637px"
                  >
                    <div
                      class="choose_lunbobd clearfix"
                      style="
                        width: 3185px;
                        position: relative;
                        overleftow: hidden;
                        padding: 0px;
                        margin: 0px;
                        left: -635px;
                      "
                    >
                      <div
                        class="bigimg left bigimg3 clone"
                        style="leftoat: left; width: 635px"
                      >
                        <div class="bigimg_mask">环境照-科汇云前台</div>
                      </div>
                      <div
                        class="bigimg left bigimg1"
                        style="leftoat: left; width: 635px"
                      >
                        <div class="bigimg_mask">环境照-科汇云前台</div>
                      </div>
                      <div
                        class="bigimg left bigimg2"
                        style="leftoat: left; width: 635px"
                      >
                        <div class="bigimg_mask">环境照-科汇云前台</div>
                      </div>
                      <div
                        class="bigimg left bigimg3"
                        style="leftoat: left; width: 635px"
                      >
                        <div class="bigimg_mask">环境照-科汇云前台</div>
                      </div>
                      <div
                        class="bigimg left bigimg1 clone"
                        style="leftoat: left; width: 635px"
                      >
                        <div class="bigimg_mask">环境照-科汇云前台</div>
                      </div>
                    </div>
                  </div>
                  <ul class="bigimg_point">
                    <li class="on"></li>
                    <li class=""></li>
                    <li class=""></li>
                  </ul>-->
                </div>
              </div>
              <div class="turning_turn_choose_showyuzhua_down clearfix">
                <div class="turning_turn_choose_showyuzhua_down_each left">
                  <img
                    src="https://style.yuzhua.com/public/mark/3.0/pdetail/images/choose_icon1.png"
                    alt="choose_icon1"
                  />
                  <p class="p1">明码标价<i></i>拒绝差价</p>
                  <p class="p2">商标交易无猫腻让您买的更安心</p>
                </div>
                <div class="turning_turn_choose_showyuzhua_down_each left">
                  <img
                    src="https://style.yuzhua.com/public/mark/3.0/pdetail/images/choose_icon2.png"
                    alt="choose_icon2"
                  />
                  <p class="p1">百万商标<i></i>汇聚全网</p>
                  <p class="p2">不同风格的商标总有一款适合您</p>
                </div>
                <div class="turning_turn_choose_showyuzhua_down_each left">
                  <img
                    src="https://style.yuzhua.com/public/mark/3.0/pdetail/images/choose_icon3.png"
                    alt="choose_icon3"
                  />
                  <p class="p1">专属客服<i></i>专业服务</p>
                  <p class="p2">资深顾问让您的商标选购过程更省心</p>
                </div>
                <div class="turning_turn_choose_showyuzhua_down_each left">
                  <img
                    src="https://style.yuzhua.com/public/mark/3.0/pdetail/images/choose_icon4.png"
                    alt="choose_icon4"
                  />
                  <p class="p1">层层把控<i></i>交易无忧</p>
                  <p class="p2">全程保护您的交易安全</p>
                </div>
              </div>
            </div>
          </div>
          <!-- why choose kehuiyun e -->
          <!-- Honor wall s -->
          <div class="honorwall turn7" id="turn6">
            <p class="honorwall_t">
              <i class="el-icon-menu" style="margin-right: 10px"></i>荣誉墙<i
                style="margin-left: 10px"
                class="el-icon-menu"
              ></i>
            </p>
            <p class="honorwall_et">YUZHUA WALL OF FAME</p>
            <div class="honorwall_imgs">
              <div class="honorwall_imgs_l">
                <img
                  src="../../../assets/img/zhichanyun/ry1.jpg"
                  alt="honor_img1.png"
                />
              </div>
              <div class="honorwall_imgs_m">
                <img
                  src="../../../assets/img/zhichanyun/ry3.jpg"
                  alt="honor_img2.png"
                />
              </div>
              <div class="honorwall_imgs_r">
                <img
                  src="../../../assets/img/zhichanyun/ry2.jpg"
                  alt="honor_img3.png"
                />
              </div>
            </div>
          </div>
          <!-- Honor wall s -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { PatentXQ } from "../../../common/js/api";
import { formatDate } from "../../../common/js/times"; //时间
import { urlImg } from "@/common/js/url";
export default {
  data() {
    return {
      ID: "",
      XQ: {},
      urlImg: "",
      styleObject: {
        position: "static",
        width: "",
      },
      swiperList: [
        { img: require("../../../assets/img/zhichanyun/hj4.jpg") },
        { img: require("../../../assets/img/zhichanyun/hj3.jpg") },
        { img: require("../../../assets/img/zhichanyun/hj5.jpg") },
      ],
      tab: [
        { name: "知识产权信息" },
        { name: "知识产权详情" },
        { name: "购买流程" },
        { name: "过户材料" },
        { name: "安全保障" },
        { name: "科汇实力" },
        { name: "荣誉墙" },
      ],
      index: 0,
    };
  },
  filters: {
    formatDate(time) {
      time = time * 1000;
      let date = new Date(time);
      return formatDate(date, "yyyy-MM-dd");
    },
  },
  created() {
    this.ID = this.$route.query.id;

    this.urlImg = urlImg();
    this.xq();
  },

  methods: {
    // tab栏

    goNavList(e, idx) {
      // console.log(e);
      this.index = idx;
      if (idx == 0) {
        //  this.$refs["turn0"].scrollIntoView(true)  ;
        document.documentElement.scrollTop = 610;
      } else if (idx == 1) {
        document.documentElement.scrollTop = 776;
      } else if (idx == 2) {
        document.documentElement.scrollTop = 930;
      } else if (idx == 3) {
        document.documentElement.scrollTop = 1169;
      } else if (idx == 4) {
        document.documentElement.scrollTop = 1710;
      } else if (idx == 5) {
        document.documentElement.scrollTop = 1920;
      } else {
        document.documentElement.scrollTop = 2200;
      }
    },
    // 获取科创活动详情
    xq() {
      PatentXQ({
        id: this.ID,
      })
        .then((res) => {
          // console.log(res);
          this.XQ = res.data;
          this.XQ.notary_picture = JSON.parse(this.XQ.notary_picture);
        })
        .catch(() => {});
    },

    handleScroll() {
      //获取滚动时的高度
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      var that = this;
      console.log(scrollTop);
      if (scrollTop > 610) {
        //大于200的时候要做的操作
        that.styleObject.position = "fixed";
        that.styleObject.width = "100%";
        that.index = 0;
      } else {
        that.styleObject.position = "static";
        that.styleObject.width = "";
      }
      if (scrollTop > 775) {
        //大于200的时候要做的操作
        that.index = 1;
      }
      if (scrollTop > 920) {
        //大于200的时候要做的操作
        that.index = 2;
      }
      if (scrollTop > 1160) {
        //大于200的时候要做的操作
        that.index = 3;
      }
      if (scrollTop > 1700) {
        //大于200的时候要做的操作
        that.index = 4;
      }
      if (scrollTop > 1910) {
        //大于200的时候要做的操作
        that.index = 5;
      }
      if (scrollTop > 2190) {
        //大于200的时候要做的操作
        that.index = 6;
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    document.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="less" scoped>
.details-one {
  background-color: rgba(248, 249, 250, 100);
  margin-top: 150px;
}
.head_detail {
  background: #fff;
  padding-top: 16px;

  .head_detail_img {
    width: 311px;
    margin: 0 10px 0 16px;
    .head_detail_img_show {
      position: relative;
      width: 311px;
      height: 311px;
      display: table;
      text-align: center;
      margin-bottom: 19px;
      div {
        display: table-cell;
        vertical-align: middle;
        img {
          max-width: 311px;
          max-height: 311px;
        }
      }
      .hot_icon {
        width: 86px;
        height: 26px;
        position: absolute;
        top: 0;
        left: 0;
      }
      .ptypes {
        position: absolute;
        top: 0;
        left: 0;
        width: 60px;
        height: 25px;
        display: inline-block;
        font-size: 12px;
        color: #fff;
        line-height: 25px;
        text-indent: 8px;
        margin-right: 1px;
      }
    }
  }
  .head_detail_show {
    width: 860px;
    .head_detail_show_title {
      font-size: 30px;
      color: #404040;
      font-weight: bold;
      position: relative;
      display: inline-block;
      max-width: 546px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 10px;
      .head_detail_show_expiry {
        font-size: 18px;
        color: #333;
        line-height: 1.3;
      }
    }
    .head_detail_show_price {
      width: 620px;
      height: 115px;
      background: url(../../../assets/img/zhichanyun/detail_bg.png) no-repeat
        center;
      margin-top: 9px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      padding: 15px 0 0 10px;
      span:first-child {
        font-size: 14px;
        color: #878787;
      }
      .price:before {
        content: "￥";
        font-size: 16px;
        color: #ff5a28;
        font-weight: bold;
      }
      .price {
        font-size: 30px;
        color: #ff4e00;
        font-weight: bold;
      }
    }
  }
  .head_detail_show_type {
    width: 618px;
    height: 116px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding-top: 35px;
    margin-left: 10px;
    // border-bottom: 1px dashed #d2d2d2;
    .head_detail_show_type_each:first-child {
      padding-left: 0;
    }
    .head_detail_show_type_each:last-child {
      border-right: none;
    }
    .head_detail_show_type_each {
      width: 120px;
      text-align: left;
      padding-left: 25px;
      border-right: 1px solid #e6e6e6;
      .head_detail_show_type_each_p1 {
        font-size: 18px;
        color: #656565;
      }
      .head_detail_show_type_each_p2 {
        font-size: 14px;
        margin-top: 10px;
        color: #959595;
      }
    }
  }
  .head_detail_down {
    // width: 955px;
    height: 67px;
    border-top: 1px dotted #d2d2d2;
    margin-top: 8px;
    span {
      display: inline-block;
      height: 67px;
      line-height: 67px;
      padding: 0 25px;
      position: relative;
      font-size: 14px;
      color: #8a8a8a;
    }
    .head_detail_down_r {
      margin-right: 15px;
      font-size: 14px;
      color: #ff580e;
      line-height: 67px;
    }
  }
}
.turning {
  width: 1200px;
  margin: 20px auto 0 auto;
  // tab
  .turning_turn {
    width: 100%;
    position: relative;
    padding: 0px 0 50px 0;
    background: #fff;
    .turning_turn_wrap {
      width: 100%;
      height: 60px;
      border-bottom: 1px solid #eaeaea;
      .turning_turn_nav {
        width: 850px;
        height: 60px;
        background: #fff;
        margin: auto;
        span {
          display: block;
          margin-left: 45px;
          height: 60px;
          line-height: 60px;
          font-size: 16px;
          color: #656565;
          position: relative;
          cursor: pointer;
          user-select: none;
        }
        .on {
          color: #ff580e;
        }

        .on:after {
          content: "";
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 3px;
          background: #fb6514;
        }
      }
    }
  }
  //标题
  .commont {
    width: 852px;
    margin: auto;
    background: url(../../../assets/img/zhichanyun/icon-5.png) no-repeat center
      right;
    i {
      margin-right: 10px;
    }
  }
  // 知识产权信息
  .turning_turn_info {
    .commont {
      margin: 34.5px auto 27px auto;
    }
    .turning_turn_info_table {
      width: 860px;
      height: 144px;
      margin: auto;
      border-radius: 4px;
      border: 1px solid #e6e6e6;
      .table {
        box-sizing: content-box;
        height: 144px;
        text-align: center;
        border-right: 1px solid #e6e6e6;
        .turning_turn_info_table_col_up {
          height: 56px;
          line-height: 56px;
          font-size: 14px;
          color: #5a5a5a;
          font-weight: bold;
          border-bottom: 1px solid #e6e6e6;
        }
        .down {
          width: 100%;
          height: 85px;
          display: table;
          .turning_turn_info_table_col_down_in {
            display: table-cell;
            vertical-align: middle;
            font-size: 14px;
            color: #949494;
          }
        }
      }

      .turning_turn_info_table_col1 {
        width: 142px;
      }
      .turning_turn_info_table_col2 {
        width: 180px;

        p {
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4;
          overflow: hidden;
        }
      }
      .turning_turn_info_table_col3 {
        width: 92px;
      }
      .turning_turn_info_table_col4 {
        width: 105px;
      }
      .turning_turn_info_table_col5 {
        width: 112px;
      }
      .turning_turn_info_table_col6 {
        width: 112px;
      }
      .turning_turn_info_table_col7 {
        width: 101px;
        border: 0;
      }
    }
  }
  // 知识产权详情
  .turning_turn_pdetail {
    padding-top: 30px;

    .turning_turn_pdetail_show {
      width: 850px;
      margin: auto;
      text-align: left;
      p {
        font-size: 14px;
        color: #939393;
        line-height: 28px;
      }
    }
  }
  // 购买流程
  .turning_turn_process {
    padding-top: 34px;
    .turning_turn_process_chart {
      width: 850px;
      height: 200px;
      border-radius: 4px;
      background: url(../../../assets/img/zhichanyun/process_bg.png) no-repeat
        center;
      margin: 10px auto auto auto;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      padding: 0 10px 10px 10px;
      .turning_turn_process_chart_in {
        width: 100%;
        height: 100%;
        overflow: hidden;
        .first {
          border-radius: 4px 0 0 4px;
          overflow: hidden;
          background: url(../../../assets/img/zhichanyun/first.png) no-repeat
            center;
          border-right: 1px solid #d7dde7;
        }

        .second {
          overflow: hidden;
          background: url(../../../assets/img/zhichanyun/second.png) no-repeat
            center;
          border-right: 1px solid #d7dde7;
        }

        .third {
          overflow: hidden;
          background: url(../../../assets/img/zhichanyun/third.png) no-repeat
            center;
          border-right: 1px solid #d7dde7;
        }

        .fourth {
          border-radius: 0 4px 4px 0;
          overflow: hidden;
          background: url(../../../assets/img/zhichanyun/fourth.png) no-repeat
            center;
        }
        .turning_turn_process_chart_each.on {
          width: 250px;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          padding-left: 14px;
          transition: 0.3s;
          .turning_turn_process_chart_each_num {
            background: none;
            text-align: left;
            color: #fff;
            margin: 28px 0 0 0;
            height: auto;
            line-height: 18px;
            font-size: 12px;
            span {
              font-size: 30px;
            }
          }
          .turning_turn_process_chart_each_name {
            text-align: left;
            font-size: 18px;
            color: #ffffff;
            position: relative;
          }
          .turning_turn_process_chart_each_name:after {
            content: "";
            display: block;
            width: 39px;
            height: 1px;
            background: url(../../../assets/img/zhichanyun/icon-6.png) no-repeat
              center;
            position: absolute;
            bottom: -8px;
            left: 0;
          }
          .turning_turn_process_chart_each_intro {
            text-align: left;
            width: 225px;
            height: 48px;
            line-height: 24px;
            font-size: 13px;
            color: #c4cbd7;
            margin: 15px 0 0 0;
          }
          i {
            display: none;
          }
        }
        .turning_turn_process_chart_each {
          width: 144px;
          height: 178px;
          margin-top: 10px;
          cursor: pointer;
          .turning_turn_process_chart_each_num {
            width: 61px;
            height: 61px;
            margin: 18px auto auto auto;
            background: url(../../../assets/img/zhichanyun/circle.png) no-repeat
              center;
            text-align: center;
            line-height: 61px;
            font-size: 12px;
            color: #495260;
            span {
              font-size: 18px;
            }
          }
          .turning_turn_process_chart_each_name {
            font-size: 16px;
            color: #304158;
            text-align: center;
            margin: 2px 0 8px 0;
          }
          .turning_turn_process_chart_each_intro {
            font-size: 12px;
            color: #b2b5b9;
            text-align: center;
            margin: auto;
            width: 135px;
            height: 18px;
            overflow: hidden;
          }
          i {
            display: block;
            width: 18px;
            height: 18px;
            background: url(../../../assets/img/zhichanyun/other_icon1.png)
              no-repeat center;
            margin: 5px auto auto auto;
          }
        }
      }
    }
  }

  // 过户资料
  .turning_turn_transfer {
    padding-top: 35px;
    .turning_turn_transfer_table {
      width: 852px;
      border-radius: 4px;
      border: 1px solid #e2e2e2;
      margin: 8px auto auto auto;
      text-align: center;
      .turning_turn_transfer_table_title {
        width: 100%;
        height: 55px;
        line-height: 55px;
        font-size: 14px;
        color: #777777;
        font-weight: bold;
        border-bottom: 1px solid #e2e2e2;
      }
    }
  }
}
// 过户资料
.turning_turn_transfer_table_apply {
  width: 100%;
}

.turning_turn_transfer_table_apply_row1,
.turning_turn_transfer_table_apply_row2,
.turning_turn_transfer_table_apply_row3 {
  border-bottom: 1px solid #e2e2e2;
}

.turning_turn_transfer_table_apply_row1_col1,
.turning_turn_transfer_table_apply_row2_col1,
.turning_turn_transfer_table_apply_row3_col1 {
  width: 140px;
  height: 85px;
  border-right: 1px solid #e2e2e2;
  font-size: 14px;
  color: #949494;
  float: left;
  display: table;
  font-size: 14px;
  color: #6d6d6d;
  font-weight: bold;
}

.turning_turn_transfer_table_apply_row1_col1 > div,
.turning_turn_transfer_table_apply_row2_col1 > div,
.turning_turn_transfer_table_apply_row3_col1 > div {
  display: table-cell;
  vertical-align: middle;
}

.turning_turn_transfer_table_apply_row1_col2,
.turning_turn_transfer_table_apply_row2_col2,
.turning_turn_transfer_table_apply_row3_col2 {
  width: 354px;
  height: 85px;
  border-right: 1px solid #e2e2e2;
  font-size: 14px;
  color: #949494;
  float: left;
  display: table;
}

.turning_turn_transfer_table_apply_row1_col2 > div,
.turning_turn_transfer_table_apply_row2_col2 > div,
.turning_turn_transfer_table_apply_row3_col2 > div {
  display: table-cell;
  vertical-align: middle;
  line-height: 1.5;
}

.turning_turn_transfer_table_apply_row1_col3,
.turning_turn_transfer_table_apply_row2_col3,
.turning_turn_transfer_table_apply_row3_col3 {
  width: 354px;
  height: 85px;
  border-right: 1px solid #e2e2e2;
  font-size: 14px;
  color: #949494;
  float: left;
  display: table;
  border-right: none;
}

.turning_turn_transfer_table_apply_row1_col3 > div,
.turning_turn_transfer_table_apply_row2_col3 > div,
.turning_turn_transfer_table_apply_row3_col3 > div {
  display: table-cell;
  vertical-align: middle;
  line-height: 1.5;
}

.turning_turn_transfer_table_apply_row1 {
  height: 58px;
  line-height: 58px;
}

.turning_turn_transfer_table_apply_row1_col1,
.turning_turn_transfer_table_apply_row1_col2,
.turning_turn_transfer_table_apply_row1_col3 {
  height: 58px;
  font-size: 14px;
  color: #6d6d6d;
  font-weight: bold;
  float: left;
}

.turning_turn_transfer_table_apply_row2,
.turning_turn_transfer_table_apply_row3 {
  height: 85px;
}

.turning_turn_transfer_table_footer_websit,
.turning_turn_transfer_table_footer_get {
  width: 424px;
  border-right: 1px solid #e2e2e2;
  text-align: center;
  line-height: 1.5;
}

.turning_turn_transfer_table_footer_websit_t,
.turning_turn_transfer_table_footer_get_t {
  height: 59px;
  line-height: 59px;
  font-size: 14px;
  color: #777777;
  font-weight: bold;
  border-bottom: 1px solid #e2e2e2;
}

.turning_turn_transfer_table_footer_websit_con,
.turning_turn_transfer_table_footer_get_con {
  display: table;
  width: 424px;
  height: 132px;
}

.turning_turn_transfer_table_footer_websit_con > div,
.turning_turn_transfer_table_footer_get_con > div {
  display: table-cell;
  vertical-align: middle;
  font-size: 14px;
  color: #949494;
}

.turning_turn_transfer_table_footer_get {
  border-right: none;
}
// 过户资料  end

// 安全保障  start
.turning_turn_safty {
  padding-top: 35px;
  width: 850px;
  margin: auto;
}

.turning_turn_safty .safty_in_t {
  font-size: 13px;
  color: #a5a5a5;
  margin-left: 10px;
  font-weight: normal;
      background: #fff;
}

.turning_turn_safty_show {
  width: 850px;
  height: 132px;
  background: #f7f8fa;
  border: 1px solid #e2e3e4;
  border-radius: 4px;
  margin-top: 13px;
}

.turning_turn_safty_show_each {
  width: 140px;
  height: 132px;
  border-right: 1px solid #e2e3e4;
  text-align: center;
  display: table;
}

.turning_turn_safty_show_each_in {
  display: table-cell;
  vertical-align: middle;
}

.turning_turn_safty_show_each_in img {
  max-width: 48px;
  max-height: 48px;
}

.turning_turn_safty_show_each_in p {
  font-size: 14px;
  color: #949494;
  margin-top: 7px;
}

.turning_turn_safty_show_each:last-child {
  border-right: none;
}
// 安全保障  end

// 为什么选择科汇云   start
.turning_turn_choose {
  padding-top: 38px;
}

.turning_turn_choose .commont {
  background: none;
}

.turning_turn_choose .commont_info {
  font-size: 13px;
  color: #a5a5a5;
}

.turning_turn_choose_showyuzhua {
  margin: 25px auto auto auto;
  background-color: #f7f8fa;
  width: 852px;
  height: 450px;
  border-radius: 4px;
  border: solid 1px #e1e3e9;
}

.turning_turn_choose_showyuzhua_up {
  width: 100%;
  height: 310px;
}

.turning_turn_choose_showyuzhua_up .smallimg {
  width: 213px;
  height: 310px;
}

.turning_turn_choose_showyuzhua_up .smallimg_up,
.turning_turn_choose_showyuzhua_up .smallimg_down {
  width: 213px;
  height: 155px;
  position: relative;
}

.turning_turn_choose_showyuzhua_up .smallimg_up img,
.turning_turn_choose_showyuzhua_up .smallimg_down img {
  max-width: 213px;
  max-height: 155px;
}

.turning_turn_choose_showyuzhua_up .smallimg_up_mask,
.turning_turn_choose_showyuzhua_up .smallimg_down_mask {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 213px;
  height: 32px;
  background-color: rgba(36, 49, 81, 0.6);
  text-align: left;
  line-height: 32px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 8px;
  font-size: 14px;
  color: #ffffff;
}

// 轮播
.turning_turn_choose_showyuzhua_up .choose_lunbo {
  width: 635px;
  height: 310px;
  overflow: hidden;
  position: relative;
}

// .turning_turn_choose_showyuzhua_up .bigimg {
//   width: 637px;
//   height: 310px;
//   overflow: hidden;
//   position: relative;
// }
// .turning_turn_choose_showyuzhua_up .bigimg1 {
//   background: url(../../../assets/img/zhichanyun/choose_img3.jpg) no-repeat top
//     right;
// }

// .turning_turn_choose_showyuzhua_up .bigimg2 {
//   background: url(../../../assets/img/zhichanyun/choose_img4.jpg) no-repeat top
//     right;
// }

// .turning_turn_choose_showyuzhua_up .bigimg3 {
//   background: url(../../../assets/img/zhichanyun/choose_img5.jpg) no-repeat top
//     right;
// }

// .turning_turn_choose_showyuzhua_up .bigimg_point {
//   position: absolute;
//   bottom: 10px;
//   left: 25px;
//   width: 57px;
//   height: 14px;
// }

// .turning_turn_choose_showyuzhua_up .bigimg_point li {
//   display: inline-block;
//   width: 12px;
//   height: 12px;
//   border-radius: 100%;
//   border: solid 1px #e1e3e9;
//   margin-right: 5px;
// }

// .turning_turn_choose_showyuzhua_up .bigimg_point .on {
//   background: #fff;
// }

.turning_turn_choose_showyuzhua_up .bigimg_mask {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 143px;
  height: 32px;
  background-color: rgba(36, 49, 81, 0.6);
  border-radius: 16px 0px 0px 0px;
  text-align: center;
  line-height: 32px;
  font-size: 14px;
  line-height: 30px;
  color: #ffffff;
}
//
//
.turning_turn_choose_showyuzhua_down {
  width: 850px;
  height: 139px;
}

.turning_turn_choose_showyuzhua_down_each {
  width: 25%;
  height: 139px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-right: solid 1px #e1e3e9;
  padding-top: 18px;
  text-align: center;
}

.turning_turn_choose_showyuzhua_down_each img {
  width: 45px;
  height: 45px;
}
.turning_turn_choose_showyuzhua_down_each .p1 {
  font-size: 15px;
  color: #525353;
  margin-top: 10px;
}

.turning_turn_choose_showyuzhua_down_each .p1 i {
  display: inline-block;
  width: 3px;
  height: 3px;
  background-color: #97a1b5;
  border-radius: 100%;
  margin: 0 3px;
  position: relative;
  top: -4px;
}

.turning_turn_choose_showyuzhua_down_each .p2 {
  font-size: 12px;
  color: #b0b0b0;
  margin-top: 10px;
}

.turning_turn_choose_showyuzhua_down_each:last-child {
  border-right: none;
}
// 为什么选择科汇云  end

// 荣誉墙  start
.turning_turn .honorwall {
  width: 850px;
  height: 387px;
  border-radius: 4px;
  margin: 28px auto auto auto;
  background: url(../../../assets/img/zhichanyun/honor_bg.png) no-repeat center;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding-top: 25px;
}

.turning_turn .honorwall_t {
  font-size: 18px;
  color: #ffffff;
  line-height: 1.7;
}

// .turning_turn .honorwall_t:before,
// .turning_turn .honorwall_t:after {
//   content: "";
//   display: inline-block;
//   width: 10px;
//   height: 10px;
//   // background: url(../images/honor_t_icon.png) no-repeat center;
// }

// .turning_turn .honorwall_t:before {
//   margin-right: 10px;
// }

// .turning_turn .honorwall_t:after {
//   margin-left: 10px;
// }

.turning_turn .honorwall_et {
  font-size: 10px;
  color: #788096;
}

.turning_turn .honorwall_imgs {
  width: 792px;
  margin: 15px auto auto auto;
  overflow: hidden;
}

.turning_turn .honorwall_imgs_l {
  width: 201px;
  height: 276px;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding-top: 8px;
  margin-right: 14px;
  float: left;
  background: rgba(255, 255, 255, 0.2);
}

.turning_turn .honorwall_imgs_l img {
  max-width: 182px;
  max-height: 259px;
}

.turning_turn .honorwall_imgs_m {
  width: 362px;
  height: 276px;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding-top: 8px;
  margin-right: 14px;
  float: left;
  background: rgba(255, 255, 255, 0.2);
}

.turning_turn .honorwall_imgs_m img {
  max-width: 346px;
  max-height: 259px;
}

.turning_turn .honorwall_imgs_r {
  width: 201px;
  height: 276px;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding-top: 8px;
  margin-right: 14px;
  float: left;
  background: rgba(255, 255, 255, 0.2);
  margin-right: 0;
}

.turning_turn .honorwall_imgs_r img {
  max-width: 182px;
  max-height: 259px;
}
@media screen and (max-width: 1200px) {
	.w{
		width: 98%;
	}
	.turning{
		width: 98%;
	}
	.details-one{
		margin-top: 15rem;
	}
	.head_detail .head_detail_show{
		flex-grow: 1;
		width: auto;
	}
	.head_detail .head_detail_show p{
		font-size: 1.4rem;
	}
	.head_detail .head_detail_img{
		width: 10rem;
		flex-shrink: 0;
	}
	.head_detail .head_detail_img .head_detail_img_show{
		width: 10rem;
		height: 10rem;
	}
	.head_detail .head_detail_img .head_detail_img_show div img{
		width: 10rem;
		height: 10rem;
	}
	.head_detail .head_detail_show .head_detail_show_title{
		font-size: 2rem;
	}
	.head_detail_show_price_row1 span{
		font-size: 1.4rem;
	}
	.head_detail .head_detail_show .head_detail_show_price .price{
		font-size: 1.6rem;
	}
	.head_detail .head_detail_show .head_detail_show_price span[data-v-8c6fb632]:first-child{
		font-size: 1.4rem;
	}
	.head_detail .head_detail_show_type .head_detail_show_type_each{
		width: 9rem;
	}
	.head_detail .head_detail_show_type .head_detail_show_type_each .head_detail_show_type_each_p1{
		font-size: 1.4rem;
	}
	.head_detail .head_detail_show_type .head_detail_show_type_each .head_detail_show_type_each_p2{
		font-size: 1.4rem;
	}
	.head_detail .head_detail_down span{
		font-size: 1.4rem;
		line-height: 4rem;
	}
	.head_detail .head_detail_down .head_detail_down_r{
		font-size: 1.5rem;
	}
	.turning .turning_turn .turning_turn_wrap .turning_turn_nav span{
		font-size: 1.5rem;
	}
	.turning .turning_turn .turning_turn_wrap .turning_turn_nav{
		width: 100%;
		height: auto;
	}
	.turning .turning_turn .turning_turn_wrap{
		height: auto;
	}
	.turning .commont{
		width: 100%;
		margin: 2rem 0;
	}
	.turning .commont span{
		font-size: 1.6rem;
	}
	.turning .commont i{
		font-size: 1.6rem;
	}
	.turning .turning_turn_info .turning_turn_info_table .turning_turn_info_table_col1{
		height: auto;
		width: 49%;
	}
	.turning .turning_turn_info .turning_turn_info_table .turning_turn_info_table_col2{
		height: auto;
		width: 49%;
	}
	.turning .turning_turn_info .turning_turn_info_table .turning_turn_info_table_col3{
		height: auto;
		width: 49%;
	}
	.turning .turning_turn_info .turning_turn_info_table .turning_turn_info_table_col4{
		height: auto;
		width: 49%;
	}
	.turning .turning_turn_info .turning_turn_info_table .turning_turn_info_table_col5{
		height: auto;
		width: 49%;
	}
	.turning .turning_turn_info .turning_turn_info_table .turning_turn_info_table_col6{
		height: auto;
		width: 49%;
	}
	.turning .turning_turn_info .turning_turn_info_table .turning_turn_info_table_col7{
		height: auto;
		width: 49%;
	}
	.turning .turning_turn_info .turning_turn_info_table{
		width: 100%;
		float: none;
		height: auto;
	}
	.turning .turning_turn_info .turning_turn_info_table .table .turning_turn_info_table_col_up{
		font-size: 1.4rem;
		height: 5rem;
	}
	.turning .turning_turn_info .turning_turn_info_table .table .down .turning_turn_info_table_col_down_in{
		font-size: 1.4rem;
	}
	.turning .turning_turn_info .commont{
		margin: 2rem 0;
	}
	.turning .turning_turn_pdetail .turning_turn_pdetail_show p{
		font-size: 1.5rem;
		line-height: 2.5rem;
	}
}
// 荣誉墙  end
</style>
